import "./App.css";

import React from "react";
import { RouterProvider } from "react-router";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import router from "./RoutesIndex";
import UserStoreProvider from "./context/UserContext";
import theme from "./config/theme";

const App: React.FC<{ className?: string }> = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <UserStoreProvider>
      <RouterProvider router={router} />
    </UserStoreProvider>
  </ThemeProvider>
);

export default App;
