import React, { useContext } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import { UserStoreContext } from "./context/UserContext";
import Home from "./pages/home/Home";
import SuspenseWrapper from "./components/SuspenseWrapper";

const Login = React.lazy(() => import("./pages/login/Login"));

export const routes = {
  home: "/home",
  login: "/login",
};

export const homeRoutes = {
  apprentice: "apprentice",
  foreman: "foreman",
  journeyman: "journeyman",
};

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user } = useContext(UserStoreContext);

  if (!user?.token) {
    return <Navigate to={`${routes.login}`} />;
  }

  return children;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={routes.home} />,
  },
  {
    path: `${routes.home}/*`,
    element: (
      <SuspenseWrapper>
        <ProtectedRoute>
          <Layout>
            <Home />
          </Layout>
        </ProtectedRoute>
      </SuspenseWrapper>
    ),
  },
  {
    path: `${routes.login}`,
    element: (
      <SuspenseWrapper>
        <Login />
      </SuspenseWrapper>
    ),
  },
  {
    path: "*",
    element: <div>page not found</div>,
  },
]);

export default router;
