import React, { useContext, useMemo } from "react";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { homeRoutes } from "../../RoutesIndex";
import { UserStoreContext } from "../../context/UserContext";
import { RoleEnum } from "../../enums/RoleEnum";

const ApprenticeIndex = React.lazy(
  () => import("./Apprentice/ApprenticeIndex")
);
const ForemanIndex = React.lazy(() => import("./Foreman/ForemanIndex"));

interface Props {
  className?: string;
}

const Home: React.FC<Props> = () => {
  const { user } = useContext(UserStoreContext);

  const [defaultRoute, Component] = useMemo(() => {
    if (user?.role === RoleEnum.apprentice) {
      // eslint-disable-next-line react/jsx-key
      return [homeRoutes.apprentice, <ApprenticeIndex />];
    }
    if (user?.role === RoleEnum.foreman) {
      // eslint-disable-next-line react/jsx-key
      return [homeRoutes.foreman, <ForemanIndex />];
    }
    return [null, null];
  }, []);

  if (!defaultRoute) {
    return null;
  }

  return (
    <Routes>
      <Route path={`${defaultRoute}/*`} element={Component} />
      <Route path="*" element={<Navigate to={defaultRoute} />} />
    </Routes>
  );
};

export default styled(Home)``;
