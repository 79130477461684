// localStorageService.ts

type LocalStorageKey = "user" | "settings" | "theme";

class LocalStorageService {
  setItem<T>(key: LocalStorageKey, value: T): void {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error("Error setting item to local storage", error);
    }
  }

  getItem<T>(key: LocalStorageKey): T | null {
    try {
      const serializedValue = localStorage.getItem(key);
      if (serializedValue === null) {
        return null;
      }
      return JSON.parse(serializedValue) as T;
    } catch (error) {
      console.error("Error getting item from local storage", error);
      return null;
    }
  }

  removeItem(key: LocalStorageKey): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing item from local storage", error);
    }
  }
}

const localStorageService = new LocalStorageService();
export default localStorageService;
