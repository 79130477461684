import React from "react";
import styled from "styled-components";
import { Palette } from "../styles/Palette";
import Footer from "./Footer";

const BAR_SIZE = 70;
const BASE_PADDING = 10;

const Layout: React.FC<{ children?: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <main className={className}>
    {/* <Header className="header" /> */}
    <div className="content">{children}</div>
    <Footer className="footer" />
  </main>
);

export default styled(Layout)`
  height: 100%;
  margin: 0 auto;
  .header,
  .footer {
    position: fixed;
    left: 0;
    right: 0;
    height: ${BAR_SIZE}px;
    padding: 0 ${BASE_PADDING}px;
  }
  .content {
    height: 100vh;
    overflow: auto;
    padding-bottom: ${BAR_SIZE + 16}px;
    // padding: ${BAR_SIZE}px ${BASE_PADDING}px ${BAR_SIZE + 16}px;
    background-color: ${Palette.gray};
  }
  .header {
    top: 0;
  }
  .footer {
    bottom: 0;
  }
  button {
    &.green {
      background-color: ${Palette.green} !important;
      color: black !important;
    }
    &.red {
      background-color: ${Palette.red} !important;
      color: black !important;
    }
  }
`;
