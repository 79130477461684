import axiosInstance from "../config/axiosConfig";
import { User } from "../models/User";
import { LoginProps } from "../pages/login/Login";

class UserService {
  public login = async (props: LoginProps): Promise<User> => {
    const res = await axiosInstance.post("/login", props);
    return new User(res);
  };
}

const userService = new UserService();
export default userService;
