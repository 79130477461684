import React, { createContext, useEffect, useMemo, useState } from "react";
import { User } from "../models/User";
import { LoginProps } from "../pages/login/Login";
import localStorageService from "../service/LocalStorageService";
import userService from "../service/UserService";
import Loading from "../components/Loading";

interface UserStoreProps {
  user?: User;
  login: (lp: LoginProps) => void;
  logout: () => void;
}

export const UserStoreContext = createContext<UserStoreProps>(
  {} as UserStoreProps
);

export function UserStoreProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User>();
  const [loadState, setLoadedState] = useState<"pending" | "loaded">("pending");
  useEffect(() => {
    setLoadedState("pending");
    const u = localStorageService.getItem<User>("user");
    setUser(u || undefined);
    setLoadedState("loaded");
  }, []);

  const login = async (loginProps: LoginProps) => {
    try {
      setLoadedState("pending");
      const u = await userService.login(loginProps);
      localStorageService.setItem<User>("user", u);
      setUser(u);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadedState("loaded");
    }
  };
  const logout = async () => {
    localStorageService.removeItem("user");
    setUser(undefined);
  };

  return (
    <UserStoreContext.Provider
      value={useMemo(
        () => ({
          user,
          login,
          logout,
        }),
        [user, login, logout]
      )}
    >
      {loadState === "pending" && <Loading />}
      {loadState === "loaded" && children}
    </UserStoreContext.Provider>
  );
}

export default UserStoreProvider;
