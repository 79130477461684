import { CircularProgress, Stack } from "@mui/material";
import React from "react";

const Loading: React.FC = () => (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Stack>
  );

export default Loading;
