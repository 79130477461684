import { RoleEnum } from "../enums/RoleEnum";

export class User {
  constructor(m: any) {
    this.userId = m.userId;

    this.token = m.token;

    this.role = m.role;

    this.apiVersion = m.apiVersion;
  }

  public userId: string;

  public token: string;

  public role: RoleEnum;

  public apiVersion: string;
}
