import React, { Suspense } from "react";
import Loading from "./Loading";

type Props = {
  children: React.ReactNode;
};

const SuspenseWrapper: React.FC<Props> = ({ children }) => <Suspense fallback={<Loading />}>{children}</Suspense>;

export default SuspenseWrapper;
