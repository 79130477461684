import { Typography } from "@mui/material";

import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
// eslint-disable-next-line import/no-unresolved
import HomeIcon from "../assets/Home.svg?react";
// eslint-disable-next-line import/no-unresolved
// import UserIcon from "../assets/User.svg?react";
// eslint-disable-next-line import/no-unresolved
import ExitIcon from "../assets/Exit.svg?react";

import { Palette } from "../styles/Palette";
import { routes } from "../RoutesIndex";
import { UserStoreContext } from "../context/UserContext";

interface Props {
  className?: string;
}

const Footer: React.FC<Props> = ({ className }) => {
  const location = useLocation();
  const { logout } = useContext(UserStoreContext);

  const isActive = (loc: string) => location.pathname.includes(loc);

  const logOutUser = () => {
    logout();
  };

  return (
    <div className={className}>
      <Link className="icon-wrapper" to={routes.home}>
        <HomeIcon
          className={`icon ${isActive(routes.home) ? "active" : ""}`}
          fontSize="large"
        />
        <Typography
          variant="body2"
          color={isActive(routes.home) ? "primary" : "black"}
        >
          Home
        </Typography>
      </Link>
      {/* <button type="button" className="icon-wrapper" onClick={() => null}>
        <UserIcon className="icon" fontSize="large" />
        <Typography variant="body2">Profile</Typography>
      </button> */}
      <button type="button" className="icon-wrapper" onClick={logOutUser}>
        <ExitIcon className="icon" fontSize="large" />
        <Typography variant="body2">Exit</Typography>
      </button>
    </div>
  );
};

export default styled(Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 16px 16px 0px 0px;
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
  z-index: 1;
  .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
  }
  .icon {
    path {
      fill: ${Palette.darkGray};
    }
  }
  .active {
    path {
      fill: ${Palette.blue};
    }
  }
  button {
    padding: 0;
    background-color: transparent;
  }
`;
