import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#091335",
    },
    secondary: {
      main: "#B94D26",
    },
    error: {
      main: "#FA372A",
    },
  },
  typography: {
    fontFamily: "Urbanist",
    body1: {
      color: "#212121",
      fontWeight: 600,
      fontSize: "1.1rem",
    },
    h5: {
      fontWeight: 700,
      fontSize: "1.625rem",
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          fontSize: "1.125rem",
          fontWeight: 700,
          textTransform: "none",
        },
        sizeLarge: {
          padding: "0.8rem 1.25rem",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: undefined,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: undefined,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderRadius: "12px !important",
          textAlign: "left",
          color: "#212121",
          fontSize: "1.1rem",
          fontWeight: 700,
          boxShadow: "0px 4px 60px 0px rgba(4, 6, 15, 0.05)",
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          gap: "1rem",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        labelPlacement: "start",
      },
      styleOverrides: {
        root: {
          justifyContent: "space-between",
          backgroundColor: "white",
          boxShadow: "0px 4px 60px 0px rgba(4, 6, 15, 0.05)",
          borderRadius: "1.25rem",
          marginLeft: "0px !important",
          marginRight: "0px !important",
          padding: "1.25rem",
        },
      },
    },
  },
});

export default theme;
